import { useContext } from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/Dataset';
import MachineLearningIcon from '@mui/icons-material/Insights';
import LinearRegressionIcon from '@mui/icons-material/ShowChart';
import RecommendationsIcon from '@mui/icons-material/ThumbUp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';
import {
  SidebarContainer,
  StyledList,
  LogoContainer,
  CompanyName,
} from './Sidebar.styled.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledDivider } from '../Divider/Divider.styled.js';
import SharedDataContext from '../../context/SharedDataContext';
import AnalysisCompletionFlagsContext from '../../context/AnalysisCompletionFlags';
import { ClientSettingsContext } from '../../context/AxiosContext.js';
import LOGO from '../../assets/scavenger-bird-blue.jpg';
import SidebarItem from './SidebarItem.js';
import { Theme } from '@mui/material';
import { StyledText } from '../Typography/Typography.styled.js';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    uploadedCSVName,
    uploadedPDFName,
    selectedVariable,
    businessProblem,
  } = useContext(SharedDataContext);
  const { isDescriptiveStatsDone, isAutoMLAnalysisDone, isLinearModelDone, problemType } =
    useContext(AnalysisCompletionFlagsContext);
  const ContextValue = useContext(ClientSettingsContext);

  const showAllSidebarItems = ContextValue?.showAllSidebarItems;
  const dashboardImages = ContextValue?.dashboardImages;

  return (
    <SidebarContainer>
      <StyledList sx={{ zIndex: 1 }}>
        <LogoContainer onClick={() => navigate('/')}>
          <img src={LOGO} height="40px" alt="Scavenger Logo" />
          {/* <Logo src={Logo} height="40px" /> */}
          <CompanyName>Scavenger</CompanyName>
        </LogoContainer>
        <StyledDivider sx={{ margin: '1rem' }} />

        {showAllSidebarItems && (
          <>
            <SidebarItem
              text="Data Upload"
              icon={<FileUploadOutlinedIcon />}
              isselected={location.pathname === '/'}
              isenabled={true}
              path="/"
            />
            <SidebarItem
              text="Recommendations"
              icon={<RecommendationsIcon />}
              isselected={location.pathname === '/recommendations'}
              isenabled={
                !!isDescriptiveStatsDone &&
                !!isAutoMLAnalysisDone &&
                !!isLinearModelDone &&
                !!selectedVariable &&
                !!businessProblem &&
                !problemType.includes('Classification') // TODO: Remove this condition after implementing classification
              }
              path="/recommendations"
            />
          </>
        )}
        <SidebarItem
          text="Chat with Data"
          icon={<ChatIcon />}
          isselected={location.pathname === '/chat'}
          isenabled={
            (!!isDescriptiveStatsDone &&
              !!isAutoMLAnalysisDone &&
              !!isLinearModelDone &&
              !problemType.includes('Classification')) || // TODO: Remove this condition after implementing classification) 
            !!uploadedPDFName
          }
          path="/chat"
        />
        {
          showAllSidebarItems &&
          <fieldset
            style={{
              border: '1px dotted gray',
              borderRadius: '10px',
              margin: '0 1rem',
              padding: '0 0.25rem 0.25rem 0.25rem',
            }}
          >
            <legend>
              <StyledText
                sx={{
                  fontWeight: 'bold',
                  color: (theme: Theme) => theme.palette.primary.main,
                  margin: '0 0.5rem',
                }}
              >
                Analysis
              </StyledText>
            </legend>
            <SidebarItem
              text="Data Overview & Analysis"
              icon={<InsightsOutlinedIcon />}
              isselected={location.pathname === '/data-overview'}
              isenabled={!!uploadedCSVName && !!selectedVariable && !problemType.includes('Classification')} // TODO: Remove this condition after implementing classification
              path="/data-overview"
            />
            <SidebarItem
              text="Analysis with Machine Learning"
              icon={<MachineLearningIcon />}
              isselected={location.pathname === '/machine-learning'}
              isenabled={!!uploadedCSVName && !!selectedVariable && !problemType.includes('Classification')} // TODO: Remove this condition after implementing classification
              path="/machine-learning"
            />
            <SidebarItem
              text="Analysis with Linear Regression"
              icon={<LinearRegressionIcon />}
              isselected={location.pathname === '/linear-regression'}
              isenabled={!!uploadedCSVName && !!selectedVariable && !problemType.includes('Classification')} // TODO: Remove this condition after implementing classification
              path="/linear-regression"
            />
          </fieldset>
        }
        {dashboardImages && (
          <SidebarItem
            text="Dashboard"
            icon={<DashboardIcon />}
            isselected={location.pathname === '/dashboard'}
            isenabled={true}
            path='/dashboard'
          />
        )}
      </StyledList>
    </SidebarContainer >
  );
};

export default Sidebar;
