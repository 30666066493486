import bestellverteilungNachLand from '../assets/dashboard/faie/bestellverteilung-nach-land.png';
import monatlicherUmsatzvergleich from '../assets/dashboard/faie/monatlicher-umsatzvergleich.png';
// import top10Produkte from '../assets/dashboard/faie/top-10-produkte.png';
import zahlungsmethodenUndUmsatz from '../assets/dashboard/faie/zahlungsmethoden-und-umsatz.png';
import geschlechterverteilungDerKunden from '../assets/dashboard/faie/geschlechterverteilung-der-kunden.png';
import topProdukteNachUmsatz from '../assets/dashboard/faie/top-produkte-nach-umsatz.png';
import pic from '../assets/dashboard/faie/123.png';

export const getClientSettings = (email: string) => {
    const emailDomain = email.split('@')[1];
    const subdomain = window.location.hostname.split('.')[0];

    // Settings for different domains
    if (subdomain === 'ace' || emailDomain === 'ace.com') {
        return {
            baseUrl: process.env.API_ACE_LLM_BASE_URL,
            showAllSidebarItems: false,
            shouldInitializeChat: false,
            suggestedPrompts: ['Wer bist du und wie kannst du mir helfen?',
                'Welche maximalen Lade- und Entladeströme unterstützt das Modell RESS-BS-19-H1?',
                'Welche Abmessungen (B*T*H, mm) hat das Modell RESS-BM-051100-L1',
            ],
            dashboardImages: null,
            title: 'ACE Tool',
        };
    } else if (subdomain === 'faie' || emailDomain === 'faie.at' || email === 'syedwebdev@gmail.com') {
        return {
            baseUrl: process.env.API_FAIE_LLM_BASE_URL,
            showAllSidebarItems: false,
            shouldInitializeChat: false,
            suggestedPrompts: ['Hallo, wer bist du und womit kannst du mir helfen?',
                'Was kann ich laut meinen Daten machen um den Umsatz zu steigern?',
                'Gibt es Trends im Markt, die ich berücksichtigen sollte?',
                'In welchen Ländern und Bundesländern mache ich wie viel Umsatz?',
                'Gibt es eine Zahlungsart, die besonders oft genutzt wird und gut funktioniert?',
                'Welche sind meine meistverkauften Produkte?',
                'An welchen Tagen mache ich besonders viel Umsatz und welche Schlüsse sollte ich daraus ziehen?',
            ],
            dashboardTitle: '10.2 million datapoints analyzed to evaluate the product strategy of FAIE.',
            dashboardImages: [
                {
                    title: 'Top Produkte nach Umsatz',
                    src: topProdukteNachUmsatz
                },
                {
                    title: 'Umsatz nach Regionen',
                    src: pic
                },
                {
                    title: 'Bestellverteilung nach Land',
                    src: bestellverteilungNachLand
                },
                {
                    title: 'Monatlicher Umsatzvergleich',
                    src: monatlicherUmsatzvergleich
                },
                // {
                //     title: 'Top 10 Produkte nach Umsatz',
                //     src: top10Produkte
                // },
                {
                    title: 'Zahlungsmethoden und Umsatz',
                    src: zahlungsmethodenUndUmsatz
                },
                {
                    title: 'Geschlechterverteilung der Kunden',
                    src: geschlechterverteilungDerKunden
                },
            ],
            context: 'https://docs.google.com/spreadsheets/d/1h6lyBDCfaxTlhtSXAAEgiFfyBnFEniH4/edit?usp=sharing&ouid=118075759849623926484&rtpof=true&sd=true',
            title: 'FAIE Tool',
        };
    } else if (subdomain === 'degewo' || emailDomain === 'degewo.de') {
        return {
            baseUrl: process.env.API_DAGEWO_LLM_BASE_URL,
            showAllSidebarItems: false,
            shouldInitializeChat: false,
            suggestedPrompts: ['Hallo, womit kannst du mir helfen?',
                'Erstelle eine öffentliche Ausschreibung für die tägliche Reinigung von Toilettenanlagen in unseren Bürogebäuden in Berlin.',
                'Welches Vergabeverfahren sollten wir wählen, wenn der geschätzte Auftragswert für die Reinigung der Toilettenanlagen 150.000 Euro beträgt?',
                'Welche Eignungsnachweise sollten wir von Bietern verlangen, um sicherzustellen, dass sie qualifiziert sind, die Reinigung unserer Toilettenanlagen durchzuführen?',
            ],
            dashboardImages: null,
            title: 'DEGEWO Tool',
        };
    } else {
        return {
            baseUrl: process.env.API_LLM_BASE_URL,
            showAllSidebarItems: true,
            shouldInitializeChat: true,
            suggestedPrompts: ['What is the most urgent recommendation?',
                'What is a ML model and how does it help in my case?',
                'Do you have any further recommendations for me based on the datasets?',
                'What are the main insights from my dataset?',
                'Assess the data quality.',],
            dashboardImages: null,
            title: null,
        };
    }
};